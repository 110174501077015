/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { AppIcon, OverrideService } from '@launchpad';
import { Trans } from 'react-i18next';

export default class ProsperRewardsFooter extends React.PureComponent<
  any,
  any
> {
  render() {
    return (
      <div className="row">
        <div className="col-md-8 footer-column">
          <h3>
            <Trans>CONTACT</Trans>
          </h3>
          <a href="mailto:contact@prosperloyalty.com" className="footer-link">
            <span>
              <Trans>Email</Trans>
            </span>{' '}
            · contact@prosperloyalty.com
          </a>
          <a href="tel:02039252759" className="footer-link">
            <span>
              <Trans>Card Services</Trans>
            </span>{' '}
            · 0203 925 2759
          </a>
          <a href="tel:02038140046" className="footer-link">
            <span>
              <Trans>Membership Services</Trans>
            </span>{' '}
            · 0203 814 0046
          </a>
          <a
            target="_blank"
            className="footer-link"
            href="https://prosperloyalty.com/terms-conditions/"
          >
            <Trans>Terms & Conditions</Trans>
          </a>
          <a
            target="_blank"
            className="footer-link"
            href="https://prosperloyalty.com/privacy-policy/"
          >
            <Trans>Privacy Policy</Trans>
          </a>
        </div>
        <div className="col-md-4 footer-column last-column">
          <h3>
            <Trans>CONNECT WITH US</Trans>
          </h3>
          <div>
            <a target="_blank" href="https://www.facebook.com/prosper2uk/">
              <AppIcon name="fa.facebook-square" style={{ fontSize: 40 }} />
            </a>
            <a target="_blank" href="https://twitter.com/Prosper2uk">
              <AppIcon name="fa.twitter" style={{ fontSize: 40 }} />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/prosper²">
              <AppIcon name="fa.linkedin-square" style={{ fontSize: 40 }} />
            </a>
          </div>
          <div className="pannovate-logo">
            <p>
              <Trans>powered by</Trans>
            </p>
            <img
              src={require('@launchpad/assets/pannovate_logo_black.svg')}
              alt="pannovate-logo"
            />
          </div>
        </div>

        <div className="last-line">
          <p>
            © {OverrideService.getStyles().APP_NAME} {new Date().getFullYear()}{' '}
            <Trans>copyright</Trans>
          </p>
        </div>
      </div>
    );
  }
}
