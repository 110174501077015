/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import RotateCard from '@launchpad/components/crud/RotateCard';
import ProsperRewardsFooter from './components/ProsperRewardsFooter';
import config from '../config';

const overrides = {
  card: {
    image: (card: any) => {
      // Render card image from BE if returned, if not render default
      const location = new URL(config.url);
      if (
        card.card.paymentDevicePresentation?.frontImage &&
        card.card.paymentDevicePresentation?.backImage
      ) {
        return (
          <RotateCard
            frontCard={
              location.origin + card.card.paymentDevicePresentation?.frontImage
            }
            backCard={
              location.origin + card.card.paymentDevicePresentation?.backImage
            }
            card={card?.card}
          />
        );
      } else if (card.card.paymentDevicePresentation?.frontImage) {
        return (
          <img
            src={
              location.origin + card.card.paymentDevicePresentation?.frontImage
            }
            className="card-page-card-image"
            alt="card"
          />
        );
      }
      return card.children;
    },
    page: {
      linkTC: () => (
        <a
          href="https://prosperloyalty.com/terms-conditions/"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          Terms & Conditions
        </a>
      )
    }
  },
  footer: {
    content: () => <ProsperRewardsFooter />
  },
  'unread-circle-color': '#000000',
  'hide-theme-switcher': true,
  'hide-language-switcher': true
};

export default overrides;
