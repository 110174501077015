import colors from '../../styles/_variables.scss';

const styles = {
  colors,

  // APP_NAME: 'Prosper² Rewards',
  APP_NAME: 'Prosper Loyalty',

  // ASSETS
  // logo: require('../../assets/rewards-logo.png'),
  logo: require('../../assets/rewards-logo-black.png'),
  frontCard: require('../../../../@prosper/assets/img/Prosper-Card-Front.png'),
  backCard: require('../../../../@prosper/assets/img/Prosper-Card-Back.png'),
  activateCardImage: require('../../../../@prosper/assets/img/activate_card.png')
};

export default { ...styles };
