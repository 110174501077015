import React from 'react';
import ProsperApp from '@prosper/Prosper';
import configuration from './configuration';

require('./styles/overrides.scss');

const ProsperRewardsApp = () => {
  return <ProsperApp configuration={configuration} />;
};

export default ProsperRewardsApp;
